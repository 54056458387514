import cookieNames from '~/enums/cookieNameEnum';
import { hasAuthorizationError } from '~/helpers/util';

export default ({ app }) => {
  app.$vsf.$gemini.client.interceptors.request.use(async (config): Promise<any> => {
    if (typeof (window) !== 'undefined' && hasAuthorizationError(app.$cookies.get(cookieNames.customerCookieName))) {
      await app.$cookies.remove(cookieNames.customerCookieName);
      await app.$cookies.remove(cookieNames.cartCookieName);
      await app.$cookies.remove(cookieNames.segmentsCookieName);

      await app.$cookies.set(cookieNames.messageCookieName, {
        message: app.i18n.t('You are not authorized, please log in.'),
        type: 'warning',
        icon: null,
        persist: false,
        title: null,
      });
      window.location.reload();
    }
    return config;
  });
};
